exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dzieki-bardzo-tsx": () => import("./../../../src/pages/dzieki-bardzo.tsx" /* webpackChunkName: "component---src-pages-dzieki-bardzo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lista-mailingowa-tsx": () => import("./../../../src/pages/lista-mailingowa.tsx" /* webpackChunkName: "component---src-pages-lista-mailingowa-tsx" */),
  "component---src-pages-mentoring-konsultacje-tsx": () => import("./../../../src/pages/mentoring-konsultacje.tsx" /* webpackChunkName: "component---src-pages-mentoring-konsultacje-tsx" */),
  "component---src-pages-o-mnie-tsx": () => import("./../../../src/pages/o-mnie.tsx" /* webpackChunkName: "component---src-pages-o-mnie-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-regulamin-sklepu-tsx": () => import("./../../../src/pages/regulamin-sklepu.tsx" /* webpackChunkName: "component---src-pages-regulamin-sklepu-tsx" */),
  "component---src-pages-sklep-tsx": () => import("./../../../src/pages/sklep.tsx" /* webpackChunkName: "component---src-pages-sklep-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-categories-tsx": () => import("./../../../src/templates/categories.tsx" /* webpackChunkName: "component---src-templates-categories-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

