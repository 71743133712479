// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';
import 'typeface-lora';
import 'typeface-fira-sans';
import 'typeface-open-sans';

import '@fontsource/montserrat';
import '@fontsource/montserrat/latin-ext.css';

import '@fontsource/open-sans';
import '@fontsource/open-sans/latin-ext.css';

import '@fontsource/fira-sans';
import '@fontsource/fira-sans/latin-ext.css';
import {Script} from "gatsby";
import React from "react";
// dziwnie wyswietlane linki!?

require('prismjs/themes/prism.css');
require('katex/dist/katex.min.css');

export const wrapPageElement = ({ element, props }) => {
    return (
        <>
            {element}
            <Script>
                {
                    `
                (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '804494');
      
    function showPopup(){
      ml('show', 'gAByyQ', true)
    }
                `
                }
            </Script>
        </>
    )
}
